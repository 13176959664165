<!--
 * @Author: jiang
 * @Date: 2021-08-18 15:30:42
 * @Description:
-->
<template>
  <div class="nav-wrap g-bg">
    <div class="title">{{ deptName}}</div>
    <div class="nav-box">
      <div
        class="nav-item"
        v-for="item in navList"
        :key="item.name"
        @click="$router.push(item.url)"
      >
        <el-badge
          :value="item.num"
          :hidden="item.num === 0"
        >
          <div class="img-box">
            <img
              class="img"
              :src="item.icon"
            >
          </div>
        </el-badge>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        // {
        //   name: '日程',
        //   num: 0,
        //   icon: require('@/assets/icons/schedule-icon.png'),
        //   url: '/manager/schedule',
        // },
        {
          name: '近期工作',
          num: 0,
          icon: require('@/assets/icons/list-icon.png'),
          url: '/manager/list',
        },
        {
          name: '工作计划、总结',
          num: 0,
          icon: require('@/assets/icons/attention-icon.png'),
          url: '/manager/attention',
        },
        {
          name: '反馈',
          num: 0,
          icon: require('@/assets/icons/feedback-icon.png'),
          url: '/manager/feedback',
        },
        {
          name: '流程',
          num: 0,
          icon: require('@/assets/icons/flow-icon.png'),
          url: '/manager/flow',
        },
        {
          name: '制度',
          num: 0,
          icon: require('@/assets/icons/system-icon.png'),
          url: '/manager/system',
        },
      ],
    }
  },
  computed: {
    deptName() {
      return this.$store.getters['user/deptName']
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-wrap {
  height: 100%;
  background: linear-gradient(218deg, #36cfc9 0%, #36cfc9 100%);

  .title {
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .nav-box {
    display: flex;
  }

  .nav-item {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-width: 0;

    + .nav-item {
      &::after {
        position: absolute;
        left: 0;
        width: 1px;
        height: 40px;
        content: '';
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
    }

    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background-color: rgba($color: #fff, $alpha: 0.3);
      border-radius: 16px;
    }

    .img {
      width: 18px;
      height: 18px;
    }

    .name {
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }

    &:hover {
      cursor: pointer;

      .img-box {
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
    }
  }
}
</style>
